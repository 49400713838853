.inputContainer {
  margin: 0.75rem 0 0.5rem 0;
  position: relative;
}

.inputIcon {
  position: absolute;
  left: 2rem;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.input {
  background: var(--secondary-light-blue);
  border: 2px solid transparent;
  padding: 0.75rem 1rem 0.75rem 3.5rem;
  border-radius: var(--radius);
  color: var(--dark-blue);
  font-size: 1rem;
}

.input:focus {
  border: 2px solid var(--dark-blue);
  border-radius: var(--radius);
  outline: none;
}
