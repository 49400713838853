@import url(https://fonts.googleapis.com/css2?family=Exo:wght@400;600;700&display=swap);
.SectionBoxComponent_boxContainer__20JmI {
  box-shadow: var(--inverted-shadow);
  padding: 2.25rem 3rem;
  margin: 5rem 3rem;
  border-radius: 30px;
  min-height: 250px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.TimelineComponent_title__2Pja0 {
  color: var(--dark-blue) !important;
  margin: 2.5rem 0 1rem 3rem;
  font-size: 1.25rem;
}

.TimelineComponent_flexParent__22iQp {
  margin-bottom: 5rem;
}

.TimelineComponent_timelineContainer__slrIt {
  display: -webkit-flex;
  display: flex;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 3rem;
  margin: 0 auto;
}

.TimelineItemComponent_flexParent__3BAiM {
  margin-bottom: 5rem;
}

.TimelineItemComponent_inputFlexContainer__2i_xU {
  display: -webkit-flex;
  display: flex;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 3rem;
}

.TimelineItemComponent_timelineItemContainer__2Y9S- {
  margin-right: 3rem;
  position: relative;
}

.TimelineItemComponent_timelineItemContainer__2Y9S-:last-child {
  margin-right: 0;
}

.TimelineItemComponent_timelineItem__21dWl {
  width: 65px;
  height: 65px;
  background-color: var(--light-blue);
  position: relative;
  transition: background ease-in 0.3s;
  border-radius: 50%;
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_pending__r1qFF {
  background-color: var(--secondary-light-blue);
}

.TimelineItemComponent_timelineItem__21dWl::before,
.TimelineItemComponent_timelineItem__21dWl::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: var(--light-blue);
  width: 3rem;
  height: 10px;
  max-width: 70px;
  transition: background ease-in 0.3s;
}

.TimelineItemComponent_timelineItemText__97Qr2 {
  position: absolute;
  margin-top: 1rem;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  left: 50%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100px;
}

.TimelineItemComponent_timelineItemText__97Qr2 h3 {
  text-align: center;
  font-size: 0.875rem;
}

.TimelineItemComponent_timelineItemContainer__2Y9S-.TimelineItemComponent_done__28ftH > span,
.TimelineItemComponent_timelineItemContainer__2Y9S-.TimelineItemComponent_inProcess__2PBR3 > span {
  color: var(--white);
}

.TimelineItemComponent_timelineItemText__97Qr2.TimelineItemComponent_inProcess__2PBR3 h3 {
  color: var(--light-blue);
}

.TimelineItemComponent_timelineItemText__97Qr2.TimelineItemComponent_pending__r1qFF h3 {
  color: var(--dark-gray);
}

.TimelineItemComponent_timelineItemText__97Qr2 p {
  margin-top: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  color: var(--dark-gray);
}

.TimelineItemComponent_timelineItemContainer__2Y9S- > img,
.TimelineItemComponent_timelineItemContainer__2Y9S- > span {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 33%;
  left: 50%;
}
.TimelineItemComponent_timelineItemContainer__2Y9S- > img {
  width: 24px;
}

.TimelineItemComponent_timelineItemContainer__2Y9S- > span {
  display: block;
  font-size: 1.5rem;
  color: var(--dark-blue);
  font-weight: bold;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.TimelineItemComponent_timelineItemContainer__2Y9S-.TimelineItemComponent_inProcess__2PBR3 > img,
.TimelineItemComponent_timelineItemContainer__2Y9S-.TimelineItemComponent_pending__r1qFF > img {
  display: none;
}

.TimelineItemComponent_timelineItemContainer__2Y9S-.TimelineItemComponent_done__28ftH span {
  display: none;
}

.TimelineItemComponent_timelineItemText__97Qr2.TimelineItemComponent_done__28ftH h3,
.TimelineItemComponent_timelineItemText__97Qr2.TimelineItemComponent_done__28ftH span {
  color: var(--green);
}

.TimelineItemComponent_timelineItemContainer__2Y9S-:nth-child(1) .TimelineItemComponent_timelineItem__21dWl::before {
  display: none;
}

.TimelineItemComponent_timelineItemContainer__2Y9S-:last-child .TimelineItemComponent_timelineItem__21dWl::after {
  display: none;
}

.TimelineItemComponent_timelineItem__21dWl::before {
  left: -32%;
}

.TimelineItemComponent_timelineItem__21dWl::after {
  right: -50%;
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_done__28ftH {
  background-color: var(--green);
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_done__28ftH::before {
  background-color: var(--green);
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_done__28ftH::after {
  background-color: var(--green);
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_pending__r1qFF {
  background-color: var(--secondary-light-blue);
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_pending__r1qFF::before {
  background-color: var(--secondary-light-blue);
}

.TimelineItemComponent_timelineItem__21dWl.TimelineItemComponent_pending__r1qFF::after {
  background-color: var(--secondary-light-blue);
}

.TitleComponent_titleContainer__1HFZI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 2rem;
}

.TitleComponent_titleIcon__L0Zto {
  max-height: 32px;
  height: 100%;
  margin-right: 0.5rem;
}

.TitleComponent_titleText__dK934 {
  color: var(--light-blue);
  font-size: 1.325rem;
}

.WizardNavigatorComponent_wizardStepContainer__2dTGg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.WizardNavigatorComponent_wizardPage__3PRc7 {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.WizardNavigatorComponent_inputsContainer__3aDvd {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.WizardNavigatorComponent_inputsContainer__3aDvd > * {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  margin-bottom: 1.5rem;
}

.WizardNavigatorComponent_selectInput__uE8Xh {
  margin-top: 0.25rem;
  width: 90%;
}

.WizardNavigatorComponent_button__1bkFJ {
  font-size: 0.875rem !important;
}

.WizardNavigatorComponent_button__1bkFJ.WizardNavigatorComponent_left__yQNzT {
  margin-right: auto;
}

.WizardNavigatorComponent_button__1bkFJ.WizardNavigatorComponent_right__1F2LJ {
  margin-left: auto;
}

.WizardNavigatorComponent_bottomButtonsContainer__1qdI6 {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.WizardNavigatorComponent_green__LoO8V {
  background-color: var(--green) !important;
}

.ButtonComponent_button__3C01A {
  background-color: #00a0d3;
  color: var(--white);
  padding: 0.7rem 1.8rem;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.125rem;
  border: 2px solid transparent;
  opacity: 0.85;
  position: relative;
}

.ButtonComponent_button__3C01A:hover {
  transition: opacity ease-in-out 0.3s;
  opacity: 1;
}

.ButtonComponent_button__3C01A:focus {
  border: 2px solid var(--dark-blue);
  border-radius: var(--radius);
  outline: none;
}

.ButtonComponent_buttonContent__1lVZc {
  padding-left: -0.2rem;
  width: 100%;
}

.ButtonComponent_left__3AwOo {
  padding-right: 0.25rem;
}

.ButtonComponent_right__207oW {
  padding-left: 0.25rem;
}

button:disabled,
button[disabled] {
  cursor: auto;
  opacity: 0.3;
}

button:disabled:hover,
button[disabled]:hover {
  opacity: 0.3;
}

.ImageComponent_image__3EchS {
  width: 100%;
  margin: 1.75rem 0;
  box-shadow: var(--shadow);
}

.TextComponent_text__2XnUK {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.LinkComponent_link__249Fm {
  text-decoration: none;
  display: inline-block;
  color: var(--dark-blue);
  margin: 0.5rem 0;
}

.ButtonLinkComponent_button__khbFY {
  all: unset;
  color: var(--dark-blue);
  cursor: pointer;
}

.Spinner_spinner__3dbdO {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: Spinner_sk-rotate__29Es_ 2s infinite linear;
  animation: Spinner_sk-rotate__29Es_ 2s infinite linear;
}

.Spinner_dot1__38wDE,
.Spinner_dot2__3AaDe {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--dark-blue);
  border-radius: 100%;

  -webkit-animation: Spinner_sk-bounce__1Uxn3 2s infinite ease-in-out;
  animation: Spinner_sk-bounce__1Uxn3 2s infinite ease-in-out;
}

.Spinner_dot2__3AaDe {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes Spinner_sk-rotate__29Es_ {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes Spinner_sk-rotate__29Es_ {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes Spinner_sk-bounce__1Uxn3 {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes Spinner_sk-bounce__1Uxn3 {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.Spinner_fullScreen__a6Xyu {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.SixUploadCertificatePage_fileInputContainer__3FeCO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 1.5rem 30%;
}

.SixUploadCertificatePage_button__8vYye {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.SixUploadCertificatePage_cancelButton__MTBpr {
  background-color: var(--blueish-gray);
}

.SixUploadCertificatePage_fileInputContainer__3FeCO p {
  margin-right: 1rem;
}

.SixUploadCertificatePage_buttonsContainer__3ODQT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.SixUploadCertificatePage_buttonsContainer__3ODQT button {
  margin: 1rem;
}

.SevenAddElectronicSalePoint_link__3lI0C {
  display: inline;
}

.SevenAddElectronicSalePoint_inline__21ccn {
  display: inline;
}

.NineVerificationPage_select__8s_HD {
  margin-top: 1rem;
}

.NineVerificationPage_buttonContainer__2LG1a {
  display: block;
  margin: 1.5rem 0 3rem 0;
  text-align: center;
}

.NineVerificationPage_button__FK85- {
  font-size: 0.85rem !important;
  padding: 0.7rem 1rem;
}

.MainLayoutComponent_mainLayout__2cLHN {
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-columns: minmax(250px, 2fr) 7fr;
  min-height: 100vh;
}

.PageContainerComponent_pageContainer__2RN6p {
  grid-area: main;
}

.MynvoiceLogoComponent_mynvoiceLogo__1Hn-t {
  width: 80%;
  max-width: 300px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SideBarComponent_sideBar__3zSvP {
  grid-area: sidebar;
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--secondary-light-blue);
  background-image: url(/static/media/bg_sidebar.c00567fd.png);
  background-size: cover;
}

.SideBarComponent_navigationMenu__dmgO4 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  white-space: nowrap;
  padding: 0 1.5rem;
}

.SideBarComponent_navItemContainer__25UBw {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1.25rem;
  -webkit-align-items: center;
          align-items: center;
}

.SideBarComponent_navItemContainer__25UBw img {
  height: 26px;
  margin-right: 1rem;
}

.SideBarComponent_navItemContainer__25UBw a,
.SideBarComponent_navItemContainer__25UBw button {
  text-decoration: none;
  font-weight: 600;
  color: var(--dark-blue);
  text-align: left;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.FormComponent_form__W-EAE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.AuthOptionsComponent_authOptions__1ge_N {
  margin: 0 0 1rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.AuthOptionsComponent_optionText___1gO6 {
  color: var(--dark-blue);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  outline-color: var(--dark-blue);
}

.AuthOptionsComponent_optionText___1gO6:nth-child(2) {
  text-align: right;
  margin-right: 0;
}

@media (min-width: 760px) {
  .AuthOptionsComponent_authOptions__1ge_N {
    display: block;
    margin: 1rem 0;
  }

  .AuthOptionsComponent_optionText___1gO6 {
    color: var(--dark-blue);
    font-size: 0.875rem;
    margin-right: 2rem;
    text-decoration: none;
    outline-color: var(--dark-blue);
  }
}

.ErrorMessageComponent_text__3NOtM {
  margin: 0rem 0 0.25rem 0;
  text-align: center;
  opacity: 0;
  -webkit-animation: var(--anim-appear);
          animation: var(--anim-appear);
  font-size: 0.875rem;
  color: transparent;
}

.ErrorMessageComponent_error__3Okqn {
  color: red;
}

.ReCaptchaComponent_captchaContainer__1fE5K {
  margin: 0.75rem 0 1rem 0;
}

.AuthFormComponent_submitButton__3ucEA {
  margin: 0.5rem 0 2rem 0;
}

.InputComponent_inputContainer__BK1by {
  margin: 0.75rem 0 0.5rem 0;
  position: relative;
}

.InputComponent_inputIcon__1plmW {
  position: absolute;
  left: 2rem;
  top: 50%;
  bottom: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.InputComponent_input__3h1rY {
  background: var(--secondary-light-blue);
  border: 2px solid transparent;
  padding: 0.75rem 1rem 0.75rem 3.5rem;
  border-radius: var(--radius);
  color: var(--dark-blue);
  font-size: 1rem;
}

.InputComponent_input__3h1rY:focus {
  border: 2px solid var(--dark-blue);
  border-radius: var(--radius);
  outline: none;
}

.AuthenticationPage_authPageContainer__3elM_ {
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
}

.AuthenticationPage_backgroundImage__wu4tX {
  background-size: cover;
  background-image: url(/static/media/bg_login.382003a4.jpg);
  background-position: center;
  -webkit-flex-basis: 65%;
          flex-basis: 65%;
}

.AuthenticationPage_authContainer__2sCoW {
  -webkit-flex-basis: 35%;
          flex-basis: 35%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 960px) {
  .AuthenticationPage_backgroundImage__wu4tX {
    -webkit-flex-basis: 30%;
            flex-basis: 30%;
  }

  .AuthenticationPage_authContainer__2sCoW {
    -webkit-flex-basis: unset;
            flex-basis: unset;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
  }
}

@media (max-width: 720px) {
  .AuthenticationPage_backgroundImage__wu4tX {
    -webkit-flex-basis: 0;
            flex-basis: 0;
  }

  .AuthenticationPage_authContainer__2sCoW {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-bottom: 5rem;
  }

  .AuthenticationPage_authContainer__2sCoW {
    padding-bottom: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Exo", sans-serif;
}

:root {
  --white: #fff;
  --black: #000;
  --dark-blue: #213f77;
  --light-blue: #66c1de;
  --gray: #b9c2d5;
  --blueish-gray: #9ea2b7;
  --dark-gray: #999999;
  --secondary-dark-blue: #00a0f4;
  --secondary-light-blue: #dfedee;
  --green: #b2d135;
  --shadow: 4px 4px 10px rgba(158, 158, 158, 0.85);
  --inverted-shadow: 0px -1px 16px -7px rgba(0, 0, 0, 0.99);
  --radius: 30px;
  --font-exo: "Exo", sans-serif;
  --anim-appear: appear 0.3s forwards;
}

body,
#root {
  min-height: 100vh;
}

/* Form Validation smooth appear */
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Toastify styles */

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #b2d135 !important;
  background: var(--green) !important;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}

