.sideBar {
  grid-area: sidebar;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-light-blue);
  background-image: url(../../../../assets/common/bg_sidebar.png);
  background-size: cover;
}

.navigationMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  padding: 0 1.5rem;
}

.navItemContainer {
  display: flex;
  margin-bottom: 1.25rem;
  align-items: center;
}

.navItemContainer img {
  height: 26px;
  margin-right: 1rem;
}

.navItemContainer a,
.navItemContainer button {
  text-decoration: none;
  font-weight: 600;
  color: var(--dark-blue);
  text-align: left;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
