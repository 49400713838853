@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;600;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Exo", sans-serif;
}

:root {
  --white: #fff;
  --black: #000;
  --dark-blue: #213f77;
  --light-blue: #66c1de;
  --gray: #b9c2d5;
  --blueish-gray: #9ea2b7;
  --dark-gray: #999999;
  --secondary-dark-blue: #00a0f4;
  --secondary-light-blue: #dfedee;
  --green: #b2d135;
  --shadow: 4px 4px 10px rgba(158, 158, 158, 0.85);
  --inverted-shadow: 0px -1px 16px -7px rgba(0, 0, 0, 0.99);
  --radius: 30px;
  --font-exo: "Exo", sans-serif;
  --anim-appear: appear 0.3s forwards;
}

body,
#root {
  min-height: 100vh;
}

/* Form Validation smooth appear */
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Toastify styles */

.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: var(--green) !important;
}

.Toastify__toast--warning {
  background: #f1c40f;
}

.Toastify__toast--error {
  background: #e74c3c;
}
