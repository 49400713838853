.flexParent {
  margin-bottom: 5rem;
}

.inputFlexContainer {
  display: flex;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
  justify-content: center;
  padding: 0 3rem;
}

.timelineItemContainer {
  margin-right: 3rem;
  position: relative;
}

.timelineItemContainer:last-child {
  margin-right: 0;
}

.timelineItem {
  width: 65px;
  height: 65px;
  background-color: var(--light-blue);
  position: relative;
  transition: background ease-in 0.3s;
  border-radius: 50%;
}

.timelineItem.pending {
  background-color: var(--secondary-light-blue);
}

.timelineItem::before,
.timelineItem::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--light-blue);
  width: 3rem;
  height: 10px;
  max-width: 70px;
  transition: background ease-in 0.3s;
}

.timelineItemText {
  position: absolute;
  margin-top: 1rem;
  transform: translate(-50%);
  left: 50%;
  user-select: none;
  width: 100px;
}

.timelineItemText h3 {
  text-align: center;
  font-size: 0.875rem;
}

.timelineItemContainer.done > span,
.timelineItemContainer.inProcess > span {
  color: var(--white);
}

.timelineItemText.inProcess h3 {
  color: var(--light-blue);
}

.timelineItemText.pending h3 {
  color: var(--dark-gray);
}

.timelineItemText p {
  margin-top: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  color: var(--dark-gray);
}

.timelineItemContainer > img,
.timelineItemContainer > span {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 33%;
  left: 50%;
}
.timelineItemContainer > img {
  width: 24px;
}

.timelineItemContainer > span {
  display: block;
  font-size: 1.5rem;
  color: var(--dark-blue);
  font-weight: bold;
  user-select: none;
}

.timelineItemContainer.inProcess > img,
.timelineItemContainer.pending > img {
  display: none;
}

.timelineItemContainer.done span {
  display: none;
}

.timelineItemText.done h3,
.timelineItemText.done span {
  color: var(--green);
}

.timelineItemContainer:nth-child(1) .timelineItem::before {
  display: none;
}

.timelineItemContainer:last-child .timelineItem::after {
  display: none;
}

.timelineItem::before {
  left: -32%;
}

.timelineItem::after {
  right: -50%;
}

.timelineItem.done {
  background-color: var(--green);
}

.timelineItem.done::before {
  background-color: var(--green);
}

.timelineItem.done::after {
  background-color: var(--green);
}

.timelineItem.pending {
  background-color: var(--secondary-light-blue);
}

.timelineItem.pending::before {
  background-color: var(--secondary-light-blue);
}

.timelineItem.pending::after {
  background-color: var(--secondary-light-blue);
}
