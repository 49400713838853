.fileInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 30%;
}

.button {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.cancelButton {
  background-color: var(--blueish-gray);
}

.fileInputContainer p {
  margin-right: 1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonsContainer button {
  margin: 1rem;
}
