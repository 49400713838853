.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.titleIcon {
  max-height: 32px;
  height: 100%;
  margin-right: 0.5rem;
}

.titleText {
  color: var(--light-blue);
  font-size: 1.325rem;
}
