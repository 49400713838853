.wizardStepContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.wizardPage {
  flex-grow: 1;
}

.inputsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.inputsContainer > * {
  flex-basis: 50%;
  margin-bottom: 1.5rem;
}

.selectInput {
  margin-top: 0.25rem;
  width: 90%;
}

.button {
  font-size: 0.875rem !important;
}

.button.left {
  margin-right: auto;
}

.button.right {
  margin-left: auto;
}

.bottomButtonsContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.green {
  background-color: var(--green) !important;
}
