.authOptions {
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.optionText {
  color: var(--dark-blue);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  outline-color: var(--dark-blue);
}

.optionText:nth-child(2) {
  text-align: right;
  margin-right: 0;
}

@media (min-width: 760px) {
  .authOptions {
    display: block;
    margin: 1rem 0;
  }

  .optionText {
    color: var(--dark-blue);
    font-size: 0.875rem;
    margin-right: 2rem;
    text-decoration: none;
    outline-color: var(--dark-blue);
  }
}
