.button {
  background-color: #00a0d3;
  color: var(--white);
  padding: 0.7rem 1.8rem;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.125rem;
  border: 2px solid transparent;
  opacity: 0.85;
  position: relative;
}

.button:hover {
  transition: opacity ease-in-out 0.3s;
  opacity: 1;
}

.button:focus {
  border: 2px solid var(--dark-blue);
  border-radius: var(--radius);
  outline: none;
}

.buttonContent {
  padding-left: -0.2rem;
  width: 100%;
}

.left {
  padding-right: 0.25rem;
}

.right {
  padding-left: 0.25rem;
}

button:disabled,
button[disabled] {
  cursor: auto;
  opacity: 0.3;
}

button:disabled:hover,
button[disabled]:hover {
  opacity: 0.3;
}
