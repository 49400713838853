.authPageContainer {
  display: flex;
  min-height: 100vh;
}

.backgroundImage {
  background-size: cover;
  background-image: url(../../assets/login/bg_login.jpg);
  background-position: center;
  flex-basis: 65%;
}

.authContainer {
  flex-basis: 35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 960px) {
  .backgroundImage {
    flex-basis: 30%;
  }

  .authContainer {
    flex-basis: unset;
    flex-basis: 100%;
  }
}

@media (max-width: 720px) {
  .backgroundImage {
    flex-basis: 0;
  }

  .authContainer {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5rem;
  }

  .authContainer {
    padding-bottom: 0;
  }
}
