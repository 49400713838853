.select {
  margin-top: 1rem;
}

.buttonContainer {
  display: block;
  margin: 1.5rem 0 3rem 0;
  text-align: center;
}

.button {
  font-size: 0.85rem !important;
  padding: 0.7rem 1rem;
}
