.title {
  color: var(--dark-blue) !important;
  margin: 2.5rem 0 1rem 3rem;
  font-size: 1.25rem;
}

.flexParent {
  margin-bottom: 5rem;
}

.timelineContainer {
  display: flex;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
  justify-content: center;
  padding: 0 3rem;
  margin: 0 auto;
}
