.text {
  margin: 0rem 0 0.25rem 0;
  text-align: center;
  opacity: 0;
  animation: var(--anim-appear);
  font-size: 0.875rem;
  color: transparent;
}

.error {
  color: red;
}
